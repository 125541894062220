import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import '@arc-web/components/dist/themes/index.css';
import '@arc-web/components/dist/themes/light.css';
import '@arc-web/components/dist/themes/dark.css';

import { setBasePath } from "@arc-web/components/dist/utilities/base-path.js";
setBasePath('/');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App theme={'dark'} />
  </React.StrictMode>
);
