
import '@arc-web/components/dist/components/container/arc-container.js';
import '@arc-web/components/dist/components/navbar/arc-navbar.js';
import '@arc-web/components/dist/components/sidebar/arc-sidebar.js';

import "./App.css";

import { ContainerTheme } from '@arc-web/components/dist/components/container/constants/ContainerConstants';

function App(props: { theme: ContainerTheme; }) {
    const { theme } = props;

    return (
        <arc-container theme={theme}>
            <arc-navbar slot={'nav'} logo={'/assets/adp.png'}><></></arc-navbar>
            <arc-sidebar slot={'side'} title={'Sidebar title'}>
                <div style={{padding: 'var(--arc-spacing-medium'}}>Products</div>
            </arc-sidebar>
            <section aria-label={'Demo section'} style={{padding: 'var(--arc-spacing-medium'}}>
            <div>
                The Arup Data Platform is a collection of infrastructure, tooling and resources that together enable people in Arup to work with data, including:
                    <br />* application programming interfaces (APIs)*
                    <br />* reference architectures
                    <br />* documentation and support guides
                    <br />* case studies.     
            </div>
            </section>
        </arc-container>
    );
}

export default App;
